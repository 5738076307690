import React, {Fragment} from 'react';
import logo from './logo.svg';
import './App.css';
import AboutMe from './AboutMe';
import Contact from './Contact';
import Research from './Research';
import Projects from './Projects';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LockFill, Google, MortarboardFill, EnvelopeFill, FileEarmarkCodeFill, Github, Git, CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useMediaQuery } from 'react-responsive';

// @ts-ignore
const NavItem = ({onNavClick, currentComponent, name}) => {
    return (
        <li className="nav-item">
          <a className="nav-link" onClick={onNavClick({name})}>
            {currentComponent === name?
                <b style={{color: "rgba(0,118,142,1)"}}>.{name}()</b> :
                <b>.{name}()</b>
            }
          </a>
        </li>
    );
}

// @ts-ignore
const NavLinks = ({onNavClick, currentComponent}) => {
    return (
        <ul className="navbar-nav">
          <NavItem onNavClick={onNavClick} currentComponent={currentComponent} name={"me"} />
          <NavItem onNavClick={onNavClick} currentComponent={currentComponent} name={"research"} />
          <NavItem onNavClick={onNavClick} currentComponent={currentComponent} name={"projects"} />
          <NavItem onNavClick={onNavClick} currentComponent={currentComponent} name={"contact"} />
        </ul>
    );
}

// @ts-ignore
const Header = ({onNavClick, currentComponent}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const isNotHamburger = useMediaQuery({ query: '(min-width: 500px)' });
    return (
   <nav className="navbar navbar-expand-lg navbar-light">
            <img src={logo} className="jh-logo" alt="logo" />

     <div  id="navbarText">
            {isNotHamburger ? 
                <NavLinks onNavClick={onNavClick} currentComponent={currentComponent}/> :
                isOpen ? 
                  <div className="nav-menu">
                    <NavLinks onNavClick={onNavClick} currentComponent={currentComponent}/>
                    <CaretUpFill onClick={toggleMenu} className="hamburger"/>
                  </div> :
                  <CaretDownFill onClick={toggleMenu} className="hamburger"/>
            }
       </div>
    </nav>
    );
}

function Footer() {
    return (
<footer className="text-center text-lg-start  text-muted">
  <div className="text-center p-4" style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
    <div className="footer-contacts">
      <a href="https://www.kde.cs.uni-kassel.de/hirth" className="me-4 text-reset" title="University" >
        <MortarboardFill />
      </a>
      <a href="mailto: johannes@hirthjo.org" className="me-4 text-reset" title="Email" >
          <EnvelopeFill />
      </a>
      <a href="https://matrix.to/#/@johannes:matrix.hirthjo.org" className="me-4 text-reset" title="Matrix" >
            <b style={{}}>[M]</b>
      </a>
      <a href="https://keyserver.ubuntu.com/pks/lookup?op=get&search=0x2D9F0D2E01928BC8" className="me-4 text-reset" title="PGP Key">
        <LockFill />
      </a>
      <a href="https://codeberg.org/hirthjo" className="me-4 text-reset" title="Codeberg">
            <Git />
      </a>
      <a href="https://github.com/hirthjo" className="me-4 text-reset" title="GitHub">
            <Github />
      </a>
      <a href="https://www.bibsonomy.org/bib/user/hirth/myown" className="me-4 text-reset" title="Bibtex" >
            <FileEarmarkCodeFill />
      </a>
      <a href="https://scholar.google.com/citations?user=LjSG3UMAAAAJ" className="me-4 text-reset" title="Google Scholar" >
            <Google />
      </a>
    </div>
  </div>
</footer>
    );
}

function App() {
    const [activeComponent, setActiveComponent] = React.useState('me');
    // @ts-ignore
    const handleNavClick = ({name}) => {
        return () => {
            setActiveComponent(name);
        };
    };

    return <Fragment>
        <Header onNavClick={handleNavClick} currentComponent={activeComponent}/>
          {activeComponent === "me" ? <AboutMe /> :
              activeComponent === "research" ?  <Research />:
                activeComponent === "projects" ?  <Projects />:
                  activeComponent === "contact" ?  <Contact />: <AboutMe />
              }
          <Footer />
        </Fragment>;
}

export default App;
