import React from 'react';
import './Contact.css';

function ContactImage() {
    return (
        <svg height="100%" width="auto" viewBox="0 0 177.997 110.362">
      <defs>
        <g>
          <symbol overflow="visible" id="glyph0-0">
            <path style={{stroke: "none"}} d=""/>
          </symbol>
          <symbol overflow="visible" id="glyph0-1">
            <path style={{stroke:"none"}} d="M 5.859375 -6.21875 C 5.859375 -6.703125 5.765625 -6.875 5.234375 -6.875 L 2.703125 -6.875 C 2.453125 -6.875 2.0625 -6.875 2.0625 -6.375 C 2.0625 -5.890625 2.453125 -5.890625 2.6875 -5.890625 L 4.765625 -5.890625 L 4.765625 0.625 C 4.765625 0.890625 4.765625 1.5 4.34375 2.0625 C 3.921875 2.65625 3.421875 2.65625 3.0625 2.65625 C 2.59375 2.65625 2.40625 2.625 2.171875 2.59375 C 2.1875 2.546875 2.1875 2.515625 2.1875 2.40625 C 2.1875 1.953125 1.8125 1.6875 1.484375 1.6875 C 1.109375 1.6875 0.765625 1.953125 0.765625 2.421875 C 0.765625 3.640625 2.328125 3.640625 2.984375 3.640625 C 5.296875 3.640625 5.859375 1.71875 5.859375 0.703125 Z M 5.859375 -8.953125 C 5.859375 -9.40625 5.515625 -9.75 5.0625 -9.75 C 4.625 -9.75 4.265625 -9.40625 4.265625 -8.953125 C 4.265625 -8.515625 4.625 -8.15625 5.0625 -8.15625 C 5.515625 -8.15625 5.859375 -8.515625 5.859375 -8.953125 Z M 5.859375 -8.953125 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-2">
            <path style={{stroke:"none"}} d="M 7.4375 -3.4375 C 7.4375 -5.4375 5.953125 -7.015625 4.171875 -7.015625 C 2.390625 -7.015625 0.90625 -5.4375 0.90625 -3.4375 C 0.90625 -1.421875 2.421875 0.09375 4.171875 0.09375 C 5.921875 0.09375 7.4375 -1.4375 7.4375 -3.4375 Z M 4.171875 -0.875 C 3 -0.875 2.015625 -2.078125 2.015625 -3.546875 C 2.015625 -5 3.046875 -6.046875 4.171875 -6.046875 C 5.328125 -6.046875 6.34375 -5 6.34375 -3.546875 C 6.34375 -2.078125 5.359375 -0.875 4.171875 -0.875 Z M 4.171875 -0.875 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-3">
            <path style={{stroke:"none"}} d="M 6.8125 -4.671875 C 6.8125 -6.28125 6 -6.96875 4.734375 -6.96875 C 3.671875 -6.96875 2.953125 -6.421875 2.640625 -6.125 L 2.640625 -9.078125 C 2.640625 -9.578125 2.546875 -9.734375 2.015625 -9.734375 L 0.84375 -9.734375 C 0.59375 -9.734375 0.1875 -9.734375 0.1875 -9.25 C 0.1875 -8.765625 0.609375 -8.765625 0.828125 -8.765625 L 1.546875 -8.765625 L 1.546875 -0.96875 L 0.84375 -0.96875 C 0.59375 -0.96875 0.1875 -0.96875 0.1875 -0.484375 C 0.1875 0 0.609375 0 0.828125 0 L 3.359375 0 C 3.59375 0 4 0 4 -0.484375 C 4 -0.96875 3.609375 -0.96875 3.34375 -0.96875 L 2.640625 -0.96875 L 2.640625 -3.796875 C 2.640625 -5.390625 3.828125 -6 4.640625 -6 C 5.484375 -6 5.703125 -5.546875 5.703125 -4.59375 L 5.703125 -0.96875 L 5.078125 -0.96875 C 4.828125 -0.96875 4.4375 -0.96875 4.4375 -0.484375 C 4.4375 0 4.859375 0 5.078125 0 L 7.515625 0 C 7.75 0 8.15625 0 8.15625 -0.484375 C 8.15625 -0.96875 7.765625 -0.96875 7.5 -0.96875 L 6.8125 -0.96875 Z M 6.8125 -4.671875 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-4">
            <path style={{stroke:"none"}} d="M 5.828125 -0.515625 C 6.1875 -0.015625 6.953125 0 7.546875 0 C 8 0 8.34375 0 8.34375 -0.5 C 8.34375 -0.96875 7.953125 -0.96875 7.71875 -0.96875 C 7.046875 -0.96875 6.890625 -1.046875 6.75 -1.09375 L 6.75 -4.546875 C 6.75 -5.671875 5.890625 -7.015625 3.609375 -7.015625 C 2.921875 -7.015625 1.296875 -7.015625 1.296875 -5.84375 C 1.296875 -5.375 1.625 -5.109375 2.015625 -5.109375 C 2.25 -5.109375 2.6875 -5.265625 2.703125 -5.84375 C 2.703125 -5.984375 2.71875 -6 3.046875 -6.03125 C 3.265625 -6.046875 3.46875 -6.046875 3.625 -6.046875 C 4.828125 -6.046875 5.65625 -5.546875 5.65625 -4.421875 C 2.84375 -4.359375 0.875 -3.5625 0.875 -2.046875 C 0.875 -0.9375 1.875 0.09375 3.5 0.09375 C 4.09375 0.09375 5.078125 -0.015625 5.828125 -0.515625 Z M 5.65625 -3.46875 L 5.65625 -2.140625 C 5.65625 -1.765625 5.65625 -1.4375 5.03125 -1.140625 C 4.46875 -0.875 3.75 -0.875 3.625 -0.875 C 2.625 -0.875 1.96875 -1.421875 1.96875 -2.046875 C 1.96875 -2.828125 3.34375 -3.40625 5.65625 -3.46875 Z M 5.65625 -3.46875 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-5">
            <path style={{stroke:"none"}} d="M 2.640625 -6.125 C 2.640625 -6.625 2.640625 -6.875 2.015625 -6.875 L 0.84375 -6.875 C 0.59375 -6.875 0.1875 -6.875 0.1875 -6.375 C 0.1875 -5.890625 0.609375 -5.890625 0.828125 -5.890625 L 1.546875 -5.890625 L 1.546875 -0.96875 L 0.84375 -0.96875 C 0.59375 -0.96875 0.1875 -0.96875 0.1875 -0.484375 C 0.1875 0 0.609375 0 0.828125 0 L 3.359375 0 C 3.59375 0 4 0 4 -0.484375 C 4 -0.96875 3.609375 -0.96875 3.34375 -0.96875 L 2.640625 -0.96875 L 2.640625 -3.796875 C 2.640625 -5.390625 3.828125 -6 4.640625 -6 C 5.484375 -6 5.703125 -5.546875 5.703125 -4.59375 L 5.703125 -0.96875 L 5.078125 -0.96875 C 4.828125 -0.96875 4.4375 -0.96875 4.4375 -0.484375 C 4.4375 0 4.859375 0 5.078125 0 L 7.515625 0 C 7.75 0 8.15625 0 8.15625 -0.484375 C 8.15625 -0.96875 7.765625 -0.96875 7.5 -0.96875 L 6.8125 -0.96875 L 6.8125 -4.671875 C 6.8125 -6.28125 6 -6.96875 4.734375 -6.96875 C 3.671875 -6.96875 2.953125 -6.421875 2.640625 -6.125 Z M 2.640625 -6.125 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-6">
            <path style={{stroke:"none"}} d="M 6.75 -3.046875 C 7.09375 -3.046875 7.390625 -3.046875 7.390625 -3.640625 C 7.390625 -5.453125 6.375 -7.015625 4.296875 -7.015625 C 2.40625 -7.015625 0.875 -5.421875 0.875 -3.453125 C 0.875 -1.515625 2.484375 0.09375 4.546875 0.09375 C 6.640625 0.09375 7.390625 -1.34375 7.390625 -1.734375 C 7.390625 -2.1875 6.9375 -2.1875 6.84375 -2.1875 C 6.546875 -2.1875 6.421875 -2.140625 6.3125 -1.828125 C 5.953125 -1.015625 5.09375 -0.875 4.65625 -0.875 C 3.453125 -0.875 2.265625 -1.671875 2.015625 -3.046875 Z M 2.03125 -4 C 2.25 -5.15625 3.1875 -6.046875 4.296875 -6.046875 C 5.125 -6.046875 6.125 -5.640625 6.265625 -4 Z M 2.03125 -4 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-7">
            <path style={{stroke:"none"}} d="M 4.75 -4.0625 C 4.390625 -4.125 4.0625 -4.171875 3.671875 -4.25 C 3.203125 -4.3125 2.125 -4.515625 2.125 -5.125 C 2.125 -5.546875 2.625 -6.046875 4.140625 -6.046875 C 5.46875 -6.046875 5.6875 -5.5625 5.734375 -5.140625 C 5.75 -4.875 5.78125 -4.609375 6.28125 -4.609375 C 6.84375 -4.609375 6.84375 -4.9375 6.84375 -5.265625 L 6.84375 -6.359375 C 6.84375 -6.609375 6.84375 -7.015625 6.359375 -7.015625 C 5.984375 -7.015625 5.90625 -6.796875 5.875 -6.671875 C 5.1875 -7.015625 4.484375 -7.015625 4.171875 -7.015625 C 1.515625 -7.015625 1.140625 -5.703125 1.140625 -5.125 C 1.140625 -3.671875 2.828125 -3.390625 4.28125 -3.171875 C 5.0625 -3.046875 6.34375 -2.84375 6.34375 -2 C 6.34375 -1.40625 5.75 -0.875 4.296875 -0.875 C 3.546875 -0.875 2.65625 -1.046875 2.265625 -2.296875 C 2.1875 -2.578125 2.125 -2.75 1.703125 -2.75 C 1.140625 -2.75 1.140625 -2.421875 1.140625 -2.109375 L 1.140625 -0.5625 C 1.140625 -0.296875 1.140625 0.09375 1.625 0.09375 C 1.765625 0.09375 2.03125 0.078125 2.21875 -0.515625 C 3 0.0625 3.84375 0.09375 4.28125 0.09375 C 6.8125 0.09375 7.3125 -1.234375 7.3125 -2 C 7.3125 -3.65625 5.265625 -3.984375 4.75 -4.0625 Z M 4.75 -4.0625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-8">
            <path style={{stroke:"none"}} d="M 6.375 -7.734375 C 6.328125 -7.765625 5.875 -8.046875 5.359375 -8.046875 C 4.078125 -8.046875 3.046875 -6.671875 3.046875 -4.875 C 3.046875 -2.984375 4.15625 -1.6875 5.34375 -1.6875 C 6.125 -1.6875 7.65625 -2.359375 7.65625 -5.109375 C 7.65625 -6.15625 7.65625 -9.828125 4.84375 -9.828125 C 2.671875 -9.828125 0.703125 -7.75 0.703125 -4.875 C 0.703125 -2.03125 2.640625 0.09375 4.875 0.09375 C 6.3125 0.09375 7.65625 -0.8125 7.65625 -1.28125 C 7.65625 -1.71875 7.203125 -1.71875 7.046875 -1.71875 C 6.78125 -1.71875 6.71875 -1.71875 6.5625 -1.5625 C 6.015625 -1.046875 5.40625 -0.875 4.859375 -0.875 C 3.140625 -0.875 1.59375 -2.59375 1.59375 -4.859375 C 1.59375 -7.234375 3.203125 -8.859375 4.84375 -8.859375 C 5.296875 -8.859375 6.015625 -8.703125 6.375 -7.734375 Z M 5.359375 -2.65625 C 4.609375 -2.65625 3.9375 -3.625 3.9375 -4.859375 C 3.9375 -6.203125 4.671875 -7.078125 5.34375 -7.078125 C 6.0625 -7.078125 6.75 -6.15625 6.75 -4.859375 C 6.75 -3.5625 6.046875 -2.65625 5.359375 -2.65625 Z M 5.359375 -2.65625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-9">
            <path style={{stroke:"none"}} d="M 4.921875 -6.21875 C 4.921875 -6.703125 4.828125 -6.875 4.28125 -6.875 L 2.03125 -6.875 C 1.78125 -6.875 1.375 -6.875 1.375 -6.390625 C 1.375 -5.890625 1.78125 -5.890625 2.03125 -5.890625 L 3.828125 -5.890625 L 3.828125 -0.96875 L 1.890625 -0.96875 C 1.640625 -0.96875 1.25 -0.96875 1.25 -0.484375 C 1.25 0 1.65625 0 1.890625 0 L 6.59375 0 C 6.84375 0 7.25 0 7.25 -0.484375 C 7.25 -0.96875 6.84375 -0.96875 6.59375 -0.96875 L 4.921875 -0.96875 Z M 4.921875 -8.953125 C 4.921875 -9.40625 4.578125 -9.75 4.125 -9.75 C 3.6875 -9.75 3.328125 -9.40625 3.328125 -8.953125 C 3.328125 -8.515625 3.6875 -8.15625 4.125 -8.15625 C 4.578125 -8.15625 4.921875 -8.515625 4.921875 -8.953125 Z M 4.921875 -8.953125 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-10">
            <path style={{stroke:"none"}} d="M 3.53125 -2.96875 C 3.53125 -4.46875 4.484375 -6 6.40625 -6 C 6.421875 -5.609375 6.6875 -5.296875 7.09375 -5.296875 C 7.4375 -5.296875 7.765625 -5.546875 7.765625 -5.984375 C 7.765625 -6.296875 7.578125 -6.96875 6.25 -6.96875 C 5.4375 -6.96875 4.421875 -6.671875 3.53125 -5.671875 L 3.53125 -6.21875 C 3.53125 -6.703125 3.4375 -6.875 2.90625 -6.875 L 1.140625 -6.875 C 0.90625 -6.875 0.515625 -6.875 0.515625 -6.390625 C 0.515625 -5.890625 0.890625 -5.890625 1.140625 -5.890625 L 2.4375 -5.890625 L 2.4375 -0.96875 L 1.140625 -0.96875 C 0.90625 -0.96875 0.515625 -0.96875 0.515625 -0.5 C 0.515625 0 0.890625 0 1.140625 0 L 5.3125 0 C 5.546875 0 5.953125 0 5.953125 -0.484375 C 5.953125 -0.96875 5.546875 -0.96875 5.3125 -0.96875 L 3.53125 -0.96875 Z M 3.53125 -2.96875 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-11">
            <path style={{stroke:"none"}} d="M 3.53125 -5.890625 L 6.15625 -5.890625 C 6.390625 -5.890625 6.796875 -5.890625 6.796875 -6.375 C 6.796875 -6.875 6.40625 -6.875 6.15625 -6.875 L 3.53125 -6.875 L 3.53125 -8.171875 C 3.53125 -8.484375 3.53125 -8.828125 3 -8.828125 C 2.4375 -8.828125 2.4375 -8.5 2.4375 -8.171875 L 2.4375 -6.875 L 1.046875 -6.875 C 0.796875 -6.875 0.40625 -6.875 0.40625 -6.375 C 0.40625 -5.890625 0.796875 -5.890625 1.03125 -5.890625 L 2.4375 -5.890625 L 2.4375 -2.015625 C 2.4375 -0.484375 3.515625 0.09375 4.6875 0.09375 C 5.859375 0.09375 7.15625 -0.59375 7.15625 -2.015625 C 7.15625 -2.296875 7.15625 -2.625 6.59375 -2.625 C 6.078125 -2.625 6.0625 -2.296875 6.0625 -2.03125 C 6.046875 -1.03125 5.125 -0.875 4.765625 -0.875 C 3.53125 -0.875 3.53125 -1.703125 3.53125 -2.109375 Z M 3.53125 -5.890625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-12">
            <path style={{stroke:"none"}} d="M 5.1875 -0.984375 C 5.1875 -1.578125 4.703125 -2 4.1875 -2 C 3.59375 -2 3.1875 -1.5 3.1875 -1 C 3.1875 -0.421875 3.671875 0 4.171875 0 C 4.78125 0 5.1875 -0.5 5.1875 -0.984375 Z M 5.1875 -0.984375 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-13">
            <path style={{stroke:"none"}} d="M 3.71875 -2.8125 C 2.859375 -2.8125 2.1875 -3.546875 2.1875 -4.40625 C 2.1875 -5.3125 2.890625 -6.015625 3.71875 -6.015625 C 4.578125 -6.015625 5.25 -5.265625 5.25 -4.421875 C 5.25 -3.5 4.546875 -2.8125 3.71875 -2.8125 Z M 2.28125 -2.25 C 2.328125 -2.21875 2.9375 -1.84375 3.71875 -1.84375 C 5.1875 -1.84375 6.34375 -3 6.34375 -4.421875 C 6.34375 -4.890625 6.203125 -5.359375 5.921875 -5.796875 C 6.265625 -6 6.640625 -6.0625 6.859375 -6.078125 C 6.953125 -5.640625 7.3125 -5.53125 7.46875 -5.53125 C 7.75 -5.53125 8.109375 -5.71875 8.109375 -6.1875 C 8.109375 -6.546875 7.8125 -7.046875 6.953125 -7.046875 C 6.78125 -7.046875 6 -7.03125 5.265625 -6.484375 C 5 -6.65625 4.453125 -6.96875 3.71875 -6.96875 C 2.21875 -6.96875 1.078125 -5.765625 1.078125 -4.421875 C 1.078125 -3.734375 1.359375 -3.203125 1.59375 -2.90625 C 1.421875 -2.640625 1.28125 -2.296875 1.28125 -1.8125 C 1.28125 -1.265625 1.5 -0.859375 1.640625 -0.671875 C 0.46875 0.046875 0.46875 1.125 0.46875 1.3125 C 0.46875 2.671875 2.140625 3.65625 4.171875 3.65625 C 6.21875 3.65625 7.890625 2.65625 7.890625 1.3125 C 7.890625 0.71875 7.609375 -0.078125 6.796875 -0.515625 C 6.578125 -0.625 5.90625 -0.96875 4.484375 -0.96875 L 3.359375 -0.96875 C 3.234375 -0.96875 3.03125 -0.96875 2.90625 -1 C 2.65625 -1 2.5625 -1 2.375 -1.234375 C 2.203125 -1.453125 2.1875 -1.765625 2.1875 -1.796875 C 2.1875 -1.859375 2.21875 -2.09375 2.28125 -2.25 Z M 4.171875 2.6875 C 2.578125 2.6875 1.390625 2.015625 1.390625 1.3125 C 1.390625 1.015625 1.53125 0.515625 2.046875 0.1875 C 2.4375 -0.0625 2.578125 -0.0625 3.75 -0.0625 C 5.15625 -0.0625 6.96875 -0.0625 6.96875 1.3125 C 6.96875 2.015625 5.765625 2.6875 4.171875 2.6875 Z M 4.171875 2.6875 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-14">
            <path style={{stroke:"none"}} d="M 5.703125 -0.515625 C 5.71875 0 6.0625 0 6.34375 0 L 7.5 0 C 7.765625 0 8.15625 0 8.15625 -0.5 C 8.15625 -0.96875 7.75 -0.96875 7.515625 -0.96875 L 6.8125 -0.96875 L 6.8125 -6.21875 C 6.8125 -6.703125 6.703125 -6.875 6.171875 -6.875 L 5 -6.875 C 4.75 -6.875 4.34375 -6.875 4.34375 -6.375 C 4.34375 -5.890625 4.765625 -5.890625 4.984375 -5.890625 L 5.703125 -5.890625 L 5.703125 -2.5 C 5.703125 -1.0625 4.421875 -0.875 3.90625 -0.875 C 2.640625 -0.875 2.640625 -1.40625 2.640625 -1.90625 L 2.640625 -6.21875 C 2.640625 -6.703125 2.546875 -6.875 2.015625 -6.875 L 0.84375 -6.875 C 0.59375 -6.875 0.1875 -6.875 0.1875 -6.375 C 0.1875 -5.890625 0.609375 -5.890625 0.828125 -5.890625 L 1.546875 -5.890625 L 1.546875 -1.8125 C 1.546875 -0.28125 2.640625 0.09375 3.8125 0.09375 C 4.46875 0.09375 5.109375 -0.0625 5.703125 -0.515625 Z M 5.703125 -0.515625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-15">
            <path style={{stroke:"none"}} d="M 7.28125 -5.890625 C 7.765625 -5.890625 8.09375 -5.890625 8.09375 -6.390625 C 8.09375 -6.875 7.71875 -6.875 7.421875 -6.875 L 5.59375 -6.875 C 5.3125 -6.875 4.921875 -6.875 4.921875 -6.390625 C 4.921875 -5.890625 5.296875 -5.890625 5.59375 -5.890625 L 6.375 -5.890625 L 5.578125 -1.140625 L 5.5625 -1.140625 C 5.5 -1.5625 5.296875 -2.296875 5.09375 -2.953125 C 4.8125 -3.953125 4.765625 -4.125 4.203125 -4.125 C 4.046875 -4.125 3.75 -4.125 3.5625 -3.78125 C 3.515625 -3.671875 2.890625 -1.46875 2.828125 -1.140625 L 2.8125 -1.140625 L 1.96875 -5.890625 L 2.75 -5.890625 C 3.046875 -5.890625 3.421875 -5.890625 3.421875 -6.375 C 3.421875 -6.875 3.0625 -6.875 2.75 -6.875 L 0.921875 -6.875 C 0.640625 -6.875 0.25 -6.875 0.25 -6.390625 C 0.25 -5.890625 0.59375 -5.890625 1.0625 -5.890625 L 2.03125 -0.546875 C 2.09375 -0.171875 2.140625 0.0625 2.765625 0.0625 C 3.40625 0.0625 3.453125 -0.078125 3.75 -1.046875 C 4.140625 -2.453125 4.15625 -2.671875 4.203125 -3.03125 L 4.21875 -3.03125 C 4.296875 -2.203125 4.890625 -0.328125 4.953125 -0.203125 C 5.140625 0.0625 5.4375 0.0625 5.625 0.0625 C 6.21875 0.0625 6.265625 -0.1875 6.328125 -0.546875 Z M 7.28125 -5.890625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-16">
            <path style={{stroke:"none"}} d="M 2.640625 -6.1875 L 2.640625 -9.078125 C 2.640625 -9.578125 2.546875 -9.734375 2.015625 -9.734375 L 0.84375 -9.734375 C 0.59375 -9.734375 0.1875 -9.734375 0.1875 -9.25 C 0.1875 -8.765625 0.609375 -8.765625 0.828125 -8.765625 L 1.546875 -8.765625 L 1.546875 -0.65625 C 1.546875 -0.328125 1.546875 0 2.109375 0 C 2.640625 0 2.640625 -0.3125 2.640625 -0.71875 C 3.296875 -0.046875 3.984375 0.09375 4.5 0.09375 C 6.234375 0.09375 7.78125 -1.421875 7.78125 -3.4375 C 7.78125 -5.40625 6.359375 -6.96875 4.671875 -6.96875 C 3.90625 -6.96875 3.1875 -6.671875 2.640625 -6.1875 Z M 2.640625 -3.046875 L 2.640625 -4.203125 C 2.640625 -5.140625 3.5625 -6 4.578125 -6 C 5.75 -6 6.671875 -4.828125 6.671875 -3.4375 C 6.671875 -1.90625 5.5625 -0.875 4.453125 -0.875 C 3.203125 -0.875 2.640625 -2.28125 2.640625 -3.046875 Z M 2.640625 -3.046875 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-17">
            <path style={{stroke:"none"}} d="M 1.734375 -6.390625 C 1.703125 -6.875 1.390625 -6.875 1.09375 -6.875 L 0.59375 -6.875 C 0.34375 -6.875 -0.0625 -6.875 -0.0625 -6.390625 C -0.0625 -5.890625 0.265625 -5.890625 0.84375 -5.890625 L 0.84375 -0.96875 C 0.265625 -0.96875 -0.0625 -0.96875 -0.0625 -0.484375 C -0.0625 0 0.359375 0 0.59375 0 L 2 0 C 2.234375 0 2.640625 0 2.640625 -0.484375 C 2.640625 -0.96875 2.3125 -0.96875 1.734375 -0.96875 L 1.734375 -3.828125 C 1.734375 -5.25 2.390625 -6 3.046875 -6 C 3.40625 -6 3.640625 -5.71875 3.640625 -4.6875 L 3.640625 -0.96875 C 3.328125 -0.96875 2.9375 -0.96875 2.9375 -0.484375 C 2.9375 0 3.359375 0 3.59375 0 L 4.78125 0 C 5.015625 0 5.4375 0 5.4375 -0.484375 C 5.4375 -0.96875 5.09375 -0.96875 4.53125 -0.96875 L 4.53125 -3.828125 C 4.53125 -5.25 5.1875 -6 5.828125 -6 C 6.203125 -6 6.421875 -5.71875 6.421875 -4.6875 L 6.421875 -0.96875 C 6.125 -0.96875 5.71875 -0.96875 5.71875 -0.484375 C 5.71875 0 6.15625 0 6.375 0 L 7.578125 0 C 7.8125 0 8.21875 0 8.21875 -0.484375 C 8.21875 -0.96875 7.890625 -0.96875 7.3125 -0.96875 L 7.3125 -4.828125 C 7.3125 -5.140625 7.3125 -6.96875 5.890625 -6.96875 C 5.421875 -6.96875 4.765625 -6.75 4.3125 -6.140625 C 4.078125 -6.671875 3.640625 -6.96875 3.109375 -6.96875 C 2.59375 -6.96875 2.125 -6.734375 1.734375 -6.390625 Z M 1.734375 -6.390625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-18">
            <path style={{stroke:"none"}} d="M 4.734375 -9.078125 C 4.734375 -9.578125 4.640625 -9.734375 4.09375 -9.734375 L 1.578125 -9.734375 C 1.328125 -9.734375 0.921875 -9.734375 0.921875 -9.25 C 0.921875 -8.765625 1.34375 -8.765625 1.5625 -8.765625 L 3.640625 -8.765625 L 3.640625 -0.96875 L 1.578125 -0.96875 C 1.328125 -0.96875 0.921875 -0.96875 0.921875 -0.484375 C 0.921875 0 1.34375 0 1.5625 0 L 6.8125 0 C 7.046875 0 7.4375 0 7.4375 -0.484375 C 7.4375 -0.96875 7.0625 -0.96875 6.8125 -0.96875 L 4.734375 -0.96875 Z M 4.734375 -9.078125 "/>
          </symbol>
        </g>
        <clipPath id="clip1">
          <path d="M 6 100 L 172 100 L 172 110.363281 L 6 110.363281 Z M 6 100 "/>
        </clipPath>
      </defs>
      <g id="surface1">
        <g style={{fill:"rgb(0%,0%,0%)", fillOpacity: 1}}>
          <g id="contact-svg-johannes">
            <use xlinkHref="#glyph0-1" x="5.313" y="67.708"/> 
            <use xlinkHref="#glyph0-2" x="13.681584" y="67.708"/>
            <use xlinkHref="#glyph0-3" x="22.050168" y="67.708"/>
            <use xlinkHref="#glyph0-4" x="30.418752" y="67.708"/>
            <use xlinkHref="#glyph0-5" x="38.787336" y="67.708"/>
            <use xlinkHref="#glyph0-5" x="47.15592" y="67.708"/> 
            <use xlinkHref="#glyph0-6" x="55.524504" y="67.708"/>
            <use xlinkHref="#glyph0-7" x="63.893088" y="67.708"/>
          </g>
          <g id="contact-svg-at">
            <use xlinkHref="#glyph0-8" x="72.261672" y="67.708"/>
          </g>
          <g id="contact-svg-hirth">
            <use xlinkHref="#glyph0-3" x="80.630256" y="67.708"/>
            <use xlinkHref="#glyph0-9" x="88.99884" y="67.708"/> 
            <use xlinkHref="#glyph0-10" x="97.367424" y="67.708"/>
            <use xlinkHref="#glyph0-11" x="105.736008" y="67.708"/>
            <use xlinkHref="#glyph0-3" x="114.104592" y="67.708"/> 
          </g>
          <g id="contact-svg-jo">
            <use xlinkHref="#glyph0-1" x="122.473176" y="67.708"/> 
            <use xlinkHref="#glyph0-2" x="130.84176" y="67.708"/> 
          </g>
          <g id="contact-svg-org">
            <use xlinkHref="#glyph0-12" x="139.210344" y="67.708"/> 
            <use xlinkHref="#glyph0-2" x="147.578928" y="67.708"/> 
            <use xlinkHref="#glyph0-10" x="155.947512" y="67.708"/>
            <use xlinkHref="#glyph0-13" x="164.316096" y="67.708"/>
          </g>
        </g>
        <g id="contact-svg-user" style={{fill:"rgb(0%,0%,0%)", fillOpacity: 1}}>
          <path id="contact-svg-user-path" style={{fill:"none", strokeWidth:0.79701, strokeLinecap:"butt", strokeLinejoin:"miter", stroke:"rgb(0%,0%,0%)", strokeOpacity:1, strokeMiterlimit:10}} d="M -6.803688 -13.605844 L -6.803688 -22.676156 L 49.891625 -22.676156 L 49.891625 -13.605844 " transform="matrix(1,0,0,-1,88.999,64.609)"/>
          <use xlinkHref="#glyph0-14" x="96.072" y="99.789"/>
          <use xlinkHref="#glyph0-7" x="104.440584" y="99.789"/>
          <use xlinkHref="#glyph0-6" x="112.809168" y="99.789"/>
          <use xlinkHref="#glyph0-10" x="121.177752" y="99.789"/> 
          <rect id="contact-svg-user-hit" x="94" y="90" width="37" height="15" style={{fill:"rgb(0%,0%,0%)", fillOpacity:0, strokeOpacity:0}} />
        </g>
        <g id="contact-svg-website" style={{fill:"rgb(0%,0%,0%)", fillOpacity: 1}}>
          <path id="contact-svg-website-path" style={{fill:"none", strokeWidth: 0.79701, strokeLinecap:"butt", strokeLinejoin:"miter", stroke:"rgb(0%,0%,0%)", strokeOpacity: 1, strokeMiterlimit: 10}} d="M -6.803688 36.284781 L -6.803688 45.355094 L 81.637719 45.355094 L 81.637719 36.284781 " transform="matrix(1,0,0,-1,88.999,64.609)"/>
          <use xlinkHref="#glyph0-15" x="97.126" y="15.054"/> 
          <use xlinkHref="#glyph0-6" x="105.494584" y="15.054"/> 
          <use xlinkHref="#glyph0-16" x="113.863168" y="15.054"/>
          <use xlinkHref="#glyph0-7" x="122.231752" y="15.054"/> 
          <use xlinkHref="#glyph0-9" x="130.600336" y="15.054"/> 
          <use xlinkHref="#glyph0-11" x="138.96892" y="15.054"/> 
          <use xlinkHref="#glyph0-6" x="147.337504" y="15.054"/> 
          <rect id="contact-svg-website-hit" x="95" y="3" width="62" height="15" style={{fill:"rgb(0%,0%,0%)", fillOpacity:0, strokeOpacity:0}} />
        </g>
        <g id="contact-svg-name" style={{fill:"rgb(0%,0%,0%)", fillOpacity:1}}>
          <path id="contact-svg-name-path" style={{fill:"none", strokeWidth: 0.79701, strokeLinecap:"butt", strokeLinejoin:"miter", stroke:"rgb(0%,0%,0%)", strokeOpacity: 1, strokeMiterlimit: 10}} d="M -81.639625 13.605094 L -81.639625 22.679312 L 34.016625 22.679312 L 34.016625 13.605094 " transform="matrix(1,0,0,-1,88.999,64.609)"/>
          <use xlinkHref="#glyph0-5" x="35.978" y="36.293"/> 
          <use xlinkHref="#glyph0-4" x="44.346584" y="36.293"/>
          <use xlinkHref="#glyph0-17" x="52.715168" y="36.293"/>
          <use xlinkHref="#glyph0-6" x="61.083752" y="36.293"/> 
          <rect id="contact-svg-name-hit" x="34" y="25" width="38" height="15" style={{fill:"rgb(0%,0%,0%)", fillOpacity:0, strokeOpacity:0}} />
        </g>
        <g id="contact-svg-email" style={{fill:"rgb(0%,0%,0%)", fillOpacity:1}}>
          <path id="contact-svg-email-path"  style={{fill:"none", strokeWidth: 0.79701, strokeLinecap:"butt", strokeLinejoin:"miter", stroke:"rgb(0%,0%,0%)", strokeOpacity: 1, strokeMiterlimit: 10}} d="M -81.639625 -36.285531 L -81.639625 -45.355844 L 81.637719 -45.355844 L 81.637719 -36.285531 " transform="matrix(1,0,0,-1,88.999,64.609)"/>
          <use xlinkHref="#glyph0-6" x="27.258" y="101.228"/> 
          <use xlinkHref="#glyph0-17" x="35.626584" y="101.228"/> 
          <use xlinkHref="#glyph0-4" x="43.995168" y="101.228"/> 
          <use xlinkHref="#glyph0-9" x="52.363752" y="101.228"/>
          <use xlinkHref="#glyph0-18" x="60.732336" y="101.228"/>
          <rect id="contact-svg-email-hit" x="25" y="90" width="48" height="15" style={{fill:"rgb(0%,0%,0%)", fillOpacity:0, strokeOpacity:0}} />
        </g>
      </g>
    </svg>
    );
}

function ContactMe() {
    return (
      <div className="contact-me">  
            <div className="contact-me-text">
               <h2><b>Contact Me</b></ h2>
               <p>
                 If you want to get in touch with me,<br />
                 there are several ways to contact me.
               </p>
            </div>
      </ div>
    );
}

function ContactMap() {
    const contactMap: string = 
`{
   name: Johannes Hirth
   profession: Computer Scientist
   research interests: Explainable Artificial Intelligence
   system: emacs, nix, FOSS
   hobbies: wood working, tinkering, making stuff
   pgp-key: 0x2D9F0D2E01928BC8

   contact:    {
                 email: johannes@hirthjo.org
                 matrix: @johannes:matrix.hirthjo.org
               }

   platforms:  {
                 codeberg:  hirthjo
                 github:    hirthjo
                 bibsonomy: hirthjo
                }
}`;
    return (
      <center className="contact-map">  
               <pre id="contact-map-json" style={{fontSize: "larger"}}>{contactMap}</pre>
      </ center>
    );
}

function Contact() {
    // TODO on small screens show steckbrief json
  // <center id="contact-center">
    // <div id="contact-container">
    //     <ContactImage />
    // </div>
  // </center>
    return (
        <section id="contact" className="contact-section">
           <ContactMe />
           <ContactMap />
        </section>
);         
}

export default Contact;
