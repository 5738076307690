import React from 'react';
import './App.css';
import './AboutMe.css';
import jh from './pics/johannes_hirth.png';

function AboutMe() {
    return (
<section id="me" className="about-section">
  <div className="about-content">
    <div className="about-container">
      <div className="about-article">
        <h1><u>Johannes</u> Hirth, Dr. rer. nat.</h1><br/>
        <p>
            I am a German researcher that is interested in
            explainable artificial intelligence. With my research I
            focus on methods from Explainable Artificial Intelligence and
            Symbolic Artificial Intelligence.
        </p>
        <p>
            You can find out more about me by contacting me.
        </p>
        <ul className="keywords">
          <li id="DL-keyword">Explainable AI</li>
          <li id="SAI-keyword">Symbolic AI</li>
          <li id="TM-keyword">Topic Models</li>
          <li id="SNA-keyword">Social Network Analysis</li>
        </ul>
      </div>
      </div>
        <div className="my-image-container">
            <img className="my-image"  src={jh} alt="Johannes Hirth" />
        </div>
      </div>
  </section>);
}

export default AboutMe;
